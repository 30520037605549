import { graphql, useStaticQuery } from "gatsby";
import { PrismicSliceManipulation } from "../utils/PrismicSliceManipulation";

export const useDatenschutzData = () => {
  const datenschutzDataRaw = useStaticQuery(rawData).allPrismicPage.nodes[0];
  const datenschutzData = PrismicSliceManipulation(
    datenschutzDataRaw.data.body
  )[0];

  const metaDescription = datenschutzDataRaw.data.metadescription.text;
  const metaTitle = datenschutzDataRaw.data.metatitle.text;

  return { datenschutzData, metaDescription, metaTitle };
};

const rawData = graphql`
  {
    allPrismicPage(
      filter: { data: { title: { text: { eq: "Datenschutzerklärung" } } } }
    ) {
      nodes {
        id
        data {
          metadescription {
            text
          }
          metatitle {
            text
          }
          title {
            text
          }
          body {
            ... on PrismicPageDataBodyTextbox {
              id
              slice_type
              primary {
                description {
                  html
                }
                textboxtitle {
                  text
                }
              }
            }
          }
        }
      }
    }
  }
`;
