import React from "react";
import { useDatenschutzData } from "../query/datenschutzQuery";
import TextCard from "../components/textCard/TextCard";

const Datenschutz = () => {
  const { datenschutzData } = useDatenschutzData();
  return (
    <div className="page datenschutz">
      <TextCard
        pageTitle={true}
        headTitle={false}
        title={datenschutzData.title}
        html={datenschutzData.description}
      />
    </div>
  );
};

export default Datenschutz;
